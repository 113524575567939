// extracted by mini-css-extract-plugin
export var title = "index-module--title--1XGBb";
export var subtitle = "index-module--subtitle--J8TDx";
export var button = "index-module--button--2YDWh";
export var facebookIcon = "index-module--facebookIcon--Kz2rk";
export var twitterIcon = "index-module--twitterIcon--1_soA";
export var linkedinIcon = "index-module--linkedinIcon--2UNgv";
export var aavoIcon = "index-module--aavoIcon--21-uR";
export var image = "index-module--image--2THIp";
export var reviewText = "index-module--reviewText--1bqVT";
export var carousel = "index-module--carousel--1p0PD";